// NOTE: Must be a ISO 639 Language code:
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes

module.exports = {
  zh: {
    locale: 'zh',
    countrySpecificLocale: 'tc',
    label: '简体中文' // Chinese Simplified
  },
  tc: {
    locale: 'tc',
    countryCodes: ['zh-TW', 'zh-HK'], // Taiwan, Hong Kong
    label: '正體中文' // Chinese Traditional
  },
  cs: {
    locale: 'cs',
    label: 'Český' // Czech
  },
  fr: {
    locale: 'fr',
    label: 'Français' // French
  },
  de: {
    locale: 'de',
    label: 'Deutsch' // German
  },
  en: {
    locale: 'en',
    label: 'English', // English
    default: true
  },
  ja: {
    locale: 'ja',
    label: '日本語' // Japanese
  },
  pt: {
    locale: 'pt',
    countrySpecificLocale: 'pb',
    label: 'Português' // Portuguese
  },
  pb: {
    locale: 'pb',
    countryCodes: ['pt-BR'],
    label: 'Português (Brasil)' // Portuguese (Brazil)
  },
  pl: {
    locale: 'pl',
    label: 'Polski', // Polish
  },
  ru: {
    locale: 'ru',
    label: 'Русский' // Russian
  },
  es: {
    locale: 'es',
    label: 'Español' // Spanish
  },
  tr: {
    locale: 'tr',
    label: 'Türkçe' // Turkish
  }
}
