import locales from '../constants/locales'

const getPosition = (string, subString, index) => {
  return string.split(subString, index).join(subString).length;
}

export const getLangFromUrl = (path) => {
  const langFromUrl = path.substr(1, getPosition(path, "/", 2) - 1);
  if(langFromUrl.length > 2) {
    return locales.en.locale;
  }

  if(locales[langFromUrl]) {
    return locales[langFromUrl].locale;
  }

  return locales.en.locale;
}

const startsEndsWithSlash = path => {
  let newPath = path;
  if(!path.endsWith('/')) {
    newPath = `${newPath}/`
  }
  if(!path.startsWith('/')) {
    newPath = `/${newPath}`
  }
  return newPath;
}

export const redirectToLocalisedPage = (userLang) => {
  const { pathname } = window.location;
  const safePathName = startsEndsWithSlash(pathname);
  const lang = getLangFromUrl(safePathName);
  
  if(lang === userLang) {
    return;
  }

  localStorage.setItem('locale', userLang);

  let pagePath;

  if(userLang === "en") {
    pagePath = safePathName.replace(`/${lang}/`,'');
  } else {
    pagePath = lang === 'en' ? `/${userLang}${safePathName}` : safePathName.replace(`/${lang}/`,`/${userLang}/`);
  }

  window.location.pathname = pagePath ? pagePath : "/";  
}