/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.scss";
import smoothscroll from 'smoothscroll-polyfill';
import { redirectToLocalisedPage } from './src/utils/language';
import locales from './src/constants/locales';

smoothscroll.polyfill();

const getSupportLanguageCode = lang => {
  const locale = lang.substr(0, 2);

  // Locale not supported
  if(!Object.keys(locales).includes(locale)) {
    return null;
  }

  const localeObj = locales[locale];

  // Locale has country specific variation
  if(localeObj.countrySpecificLocale) {
    const countryLocaleObj = locales[localeObj.countrySpecificLocale];

    // Country specific locale supported
    if(countryLocaleObj.countryCodes && countryLocaleObj.countryCodes.includes(lang)) {
      return countryLocaleObj.locale;
    }
  }

  // Locale supported
  return localeObj.locale;
}

export const onClientEntry = () => {
  let userLang;
  const storageLang = localStorage.getItem('locale');

  if(storageLang) {
    userLang = storageLang
  } else {
    userLang = getSupportLanguageCode(window.navigator.language);
  }

  if(!userLang) {
    return;
  }

  redirectToLocalisedPage(userLang);
}